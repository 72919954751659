import gql from 'graphql-tag';

export const getActiveMonLogs = gql`
  query getActiveMonLogs(
    $limit: Int
    $page: Int
    $query: ActiveMonLogSortOrQuery
    $sort: ActiveMonLogSortOrQuery
    $filter: [String]
  ) {
    getActiveMonLogs(
      limit: $limit
      page: $page
      query: $query
      sort: $sort
      filter: $filter
    ) {
      items {
        id
        logId
        facilityName
        pipeline
        callType
        statusSummary
        facilityUid
        primaryContact
        logStatus
        createdAt
        updatedAt
        updater
        createdBy
      }
      totalRecords
    }
  }
`;
