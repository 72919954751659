import gql from 'graphql-tag';

export const createMonLogEntry = gql`
  mutation createMonLogEntry($monLogId: String, $input: UpdateMonLogEntry) {
    createMonLogEntry(monLogId: $monLogId, input: $input) {
      createdAt
      createdBy
      deletedAt
      id
      isResolution
      note
      time
      updatedAt
      updater
      user
      version
    }
  }
`;
