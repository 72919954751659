import gql from 'graphql-tag';

export const getMonitoringLogWithAlarms = gql`
  query getMonitoringLogWithAlarms($id: ID!) {
    getMonitoringLogWithAlarms(id: $id) {
      id
      logId
      logStatus
      logCategory
      statusSummary
      reviewer
      reviewRequestedDt
      reviewCompletedDt
      closedDt
      customerOutage
      calloutRequired
      oneCallNumber
      timeBegan
      timeOnSite
      ticketNumber
      falseAlarm
      followUpCall
      backInService
      GasReleaseTypeGrade {
        id
        name
      }
      CommFailSeverity {
        id
        name
      }
      notam
      expireDt
      firstName
      lastName
      street
      phone
      state
      county
      township
      city
      InternalOne {
        id
        firstName
        lastName
      }
      InternalTwo {
        id
        firstName
        lastName
      }
      version
      createdBy
      createdAt
      deletedAt
      updater
      updatedAt
      businessUnit {
        id
        name
      }
      MonLogCallouts {
        id
        startDt
        endDt
        Contact {
          id
          firstName
          lastName
        }
      }
      MonLogAlarms {
        id
        time
        user
        note
      }
      MonLogEntries {
        id
        time
        user
        note
        isResolution
      }
      MonLogAudits {
        time
        user
        note
      }
      MonLogNotifications {
        time
        user
        note
      }
      MonLogTypes {
        id
        name
        requiresCRER
      }
      MonLogWorkTypes {
        id
        name
        category
      }
      MonLogContacts {
        id
        createdAt
        Primary {
          id
          firstName
          lastName
          businessMobile
        }
        Supervisor {
          id
          firstName
          lastName
          businessMobile
        }
        Manager {
          id
          firstName
          lastName
          businessMobile
        }
      }
      pipeline
      Facility {
        id
        facilityName
        facilityUid
        FacilityType {
          id
          name
        }
        Aor {
          name
        }
        Company {
          name
        }
      }
      ControlRoomEventReviewEntries {
        id
        note
        controlRoomInvolved
        createdAt
        createdBy
        updatedAt
        updater
      }
    }
  }
`;
