import gql from 'graphql-tag';

export const createMonLogType = gql`
mutation createMonLogType($input: MonLogTypeInput) {
  createMonLogType(input: $input) {
    id
    name
    enabled
    createdAt
    updatedAt
    deletedAt
    version
    businessUnits {
      name
      id
    }
  }
}`;
