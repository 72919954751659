import gql from 'graphql-tag';

export const getFacilityContactsForMonLog = gql`
  query getFacilityContactsForMonLog($facilityId: String!) {
    getFacilityContactsForMonLog(facilityId: $facilityId) {
      PrimaryTechnician {
        id
        firstName
        lastName
        nickName
        adAccountName
        businessMobile
        ReportsTo {
          id
          firstName
          lastName
          businessMobile
          ReportsTo {
            id
            firstName
            lastName
            businessMobile
          }
        }
      }
      FirstOnCall {
        id
        firstName
        lastName
        nickName
        adAccountName
        businessMobile
        ReportsTo {
          id
          firstName
          lastName
          businessMobile
          ReportsTo {
            id
            firstName
            lastName
            businessMobile
          }
        }
      }
    }
  }
`;
