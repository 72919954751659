import gql from 'graphql-tag';

export const updateMonLogAlarm = gql`
  mutation updateMonLogAlarm($id: String, $input: UpdateMonLogAlarm) {
    updateMonLogAlarm(id: $id, input: $input) {
      createdAt
      createdBy
      deletedAt
      id
      note
      time
      updatedAt
      updater
      user
      version
    }
  }
`;
