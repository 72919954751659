import gql from 'graphql-tag';

export const updateMonLogEntry = gql`
  mutation updateMonLogEntry($id: String, $input: UpdateMonLogEntry) {
    updateMonLogEntry(id: $id, input: $input) {
      createdAt
      createdBy
      deletedAt
      id
      isResolution
      note
      time
      updatedAt
      updater
      user
      version
    }
  }
`;
