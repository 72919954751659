import gql from 'graphql-tag';

export const createCREREntry = gql`
  mutation createCREREntry(
    $monLogId: String
    $input: ControlRoomEventReviewEntryInput
  ) {
    createCREREntry(monLogId: $monLogId, input: $input) {
      id
      monLogId
      note
      controlRoomInvolved
      createdAt
      createdBy
      updatedAt
      updater
      deletedAt
      version
    }
  }
`;
