import gql from 'graphql-tag';

export const createMonLogAlarm = gql`
  mutation createMonLogAlarm($monLogId: String, $input: UpdateMonLogAlarm) {
    createMonLogAlarm(monLogId: $monLogId, input: $input) {
      createdAt
      createdBy
      deletedAt
      id
      note
      time
      updatedAt
      updater
      user
      version
    }
  }
`;
