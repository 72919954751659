export const M_AND_R_MAINTENANCE = 'M&R Maintenance';
export const M_AND_R_LOG_CATEGORY = 'MR';
export const A_AND_E_MAINTENANCE = 'A&E Maintenance';
export const A_AND_E_LOG_CATEGORY = 'AE';
export const SHOW_RESOLVED = 'Show Resolved';
export const SHOW_AE = 'Show A&E Maintenance';
export const SHOW_MR = 'Show M&R Maintenance';
export const SCADA_ALARMS = 'SCADA Alarm';
export const TOWER_LIGHTS = 'TOWER LIGHTS';
export const TRANSDUCER_ALARM = 'Transducer Alarm';
export const GAS_RELEASE = 'Gas Release';
export const COMM_FAIL = 'Comm Fail';
export const TOWER_LIGHTS_ML_TYPE = 'Tower Lights';

// Mon Log Statuses
export const AWAITING_REVIEW_CD = 'A';

// Mon Log Actions
export const NEW_CALL = 'new-call';
export const NEW_MR_CALL = 'new-mr-call';
export const NEW_AE_CALL = 'new-ae-call';
export const NEW_CONTROLLER_CALL = 'new-controller-call';
export const NEW_ONE_CALL = 'new-one-call';
export const RESYNC = 'resync';
export const CLOSE_ALL_TABS = 'close-all-tabs';

// Tab Labels
export const NEW_MC_SHIFT_TURNOVER = 'New MC Shift Turnover';
export const MC_SHIFT_TURNOVER = 'MC Shift Turnovers';
export const MC_ST_TAB_PREFIX = 'MC Shift Turnover: ';
export const GENERATE_MC_LOG_EXTRACT = 'Generate Log Extract';
