import gql from 'graphql-tag';

export const getAvailableMonitoringLogWorkTypes = gql`
query getMonLogWorkTypes($limit: Int, $page: Int, $query: DataTableSortOrQuery, $sort: DataTableSortOrQuery) {
  getMonLogWorkTypes(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      name
      category
    }
    totalRecords
  }
}`;
