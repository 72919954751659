import gql from 'graphql-tag';

export const updateCREREntry = gql`
  mutation updateCREREntry(
    $id: String
    $input: ControlRoomEventReviewEntryInput
  ) {
    updateCREREntry(id: $id, input: $input) {
      id
      monLogId
      note
      controlRoomInvolved
      createdAt
      createdBy
      updatedAt
      updater
      deletedAt
      version
    }
  }
`;
