import gql from 'graphql-tag';

export const queryForMonitoringLog = gql`
query getMonLogs($limit: Int, $page: Int, $query: MonLogSortOrQuery, $sort: MonLogSortOrQuery) {
  getMonLogs(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      logStatus
      logId
      statusSummary
      createdAt
      createdBy
      callTypes
      facilityName
      facilityUid
      state
      county
      township
      facilityType
      pipeline
      firstName
      lastName
    }
    totalRecords
  }
}`;
