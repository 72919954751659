import gql from 'graphql-tag';

export const updateMonLogType = gql`
mutation updateMonLogType($id: ID!, $changes: MonLogTypeInput) {
  updateMonLogType(id: $id, changes: $changes) {
    id
    name
    enabled
    createdAt
    updatedAt
    deletedAt
    version
    businessUnits {
      name
      id
    }
  }
}`;
