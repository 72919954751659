import gql from 'graphql-tag';

export const getAvailableMonitoringLogTypes = gql`
  query getMonLogTypes(
    $limit: Int
    $page: Int
    $query: MonLogTypeSortOrQuery
    $sort: MonLogTypeSortOrQuery
  ) {
    getMonLogTypes(limit: $limit, page: $page, query: $query, sort: $sort) {
      items {
        id
        name
        enabled
        version
        requiresCRER
      }
      totalRecords
    }
  }
`;
